<template>
  <b-row>
    <b-col cols="12">
      <form-dsp-partner />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import FormDspPartner from './FormDspPartner.vue'

export default {
  components: {
    BRow,
    BCol,
    FormDspPartner,
  },
}
</script>
